import React from 'react';

export default props => {
  let header = document.getElementsByClassName('panel-header');
  let content = document.getElementsByClassName('content');
  let modal = document.getElementsByClassName('modal-content');
  let height = '100%';

  if (!modal.length) {
    height =
      content.length && header.length
        ? content[0].offsetHeight + header[0].offsetHeight
        : '100%';
  }

  if (props.inside) height = '100%';

  return (
    <div className="mask-loader" style={{ height, zIndex: 5 }}>
      {props.children ? props.children : null}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="lds-dual-ring"
        style={{ background: 'none' }}
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          strokeLinecap="square"
          r="10"
          strokeWidth="3"
          stroke="#1d0e0b"
          strokeDasharray="15.707963267948966 15.707963267948966"
          transform="rotate(282.857 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="0.7s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  );
};
