export default {
  apiKey: 'AIzaSyByk64zJfj8bXfe1jt_rjf9x2QNzUHSJQ8',
  authDomain: 'placeshaker-app.firebaseapp.com',
  databaseURL: 'https://placeshaker-app.firebaseio.com',
  projectId: 'placeshaker-app',
  storageBucket: 'placeshaker-app.appspot.com',
  messagingSenderId: '129733429531',
  appId: '1:129733429531:web:56667e661cd8676d'
};

/* export default {
    apiKey: "AIzaSyCawKpbLuDFoK789bJcA8oSP6K4eKt-xKs",
    authDomain: "placeshaker-196719.firebaseapp.com",
    databaseURL: "https://placeshaker-196719.firebaseio.com",
    projectId: "placeshaker-196719",
    storageBucket: "placeshaker-196719.appspot.com",
    messagingSenderId: "887304618411"
}
 */
